import Dashboard from "./views/userDashboard";
import Profile from "./views/common/UserProfile";
import Payments from "./views/userPayments";
import Residence from './views/userResidence'

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/user",
    visible:true
  }  ,
  {
    path: "/residence",
    name: "Residence",
    icon: "tim-icons icon-square-pin",
    component: Residence,
    layout: "/user",
    visible:true
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "tim-icons icon-coins",
    component: Payments,
    layout: "/user",
    visible:true
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "tim-icons icon-single-02",
    component: Profile,
    layout: "/user",
    visible:true
  }
];
export default routes;

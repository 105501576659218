import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import Receipt from './common/receipt'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Row,
  Col,
  CardTitle,
  Table,  Modal, ModalHeader, ModalBody, ModalFooter , Button
} from "reactstrap";
function Payments() {
  const [payments, setPayments] = useState(null);
  const toggleReceipt = () => setReceiptModal(!receiptModal);
  const [receiptModal, setReceiptModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const getResource = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json", 
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      },
    }).then(async (response) => {
      const data = response.data;
      console.log("use", data.data);
      if(data.data.length > 0){setter(data.data)};
    });
  };

  useEffect(() => {
    getResource(
      `/payments?initiatedBy=${localStorage.user_id}`, 
      setPayments
    );
  }, []);
  return ( 
    <>
      <div className="content">
        {payments &&<Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Payments</h5>
              </CardHeader>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">All Payments</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>id</th>
                            <th>Date</th>
                            <th>Transaction Id</th>
                            <th>Method</th>
                            <th>Amount</th>
                            <th>Number</th>
                            <th>House</th>
                            <th>Paid By</th>
                            <th>Status</th>
                            <th>Receipt</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payments &&
                            payments.map((payment, index) => {
                              const { id,reference,transaction_id, msisdn, status ,amount,method , createdAt , House , Customer ,PaymentCollection} = payment
                              const selectedHouse = House.find(item => {return item.id = item.PaymentCollection.HouseId}) || []
                              const selectedUser = Customer.find(item => {return item.id = item.PaymentCollection.UserId}) || []
                              return (
                                <tr key={index}>
                                  <td>{id}</td>
                                  <td>{payment? moment(createdAt).format('MMMM Do YYYY, h:mm:ss a'): ''}</td>
                                  <td>{transaction_id}</td>
                                  <td>{method}</td>
                                  <td>{amount}</td>
                                  <td>{msisdn}</td>
                                  <td>{selectedHouse.number}</td>
                                  <td>{selectedUser.firstName}</td>
                                  <td>{status}</td>
                                  <td> 
                                    <Button
                                      className="btn-neutral btn-round btn-simple"
                                      color="default"
                                      disabled={false}
                                      onClick={() => {
                                        setReceiptModal(true)
                                        setSelectedItem(payment)
                                        //console.log(payment[index]);
                                      }}
                                    >
                                      {/* <i className="tim-icons icon-simple-remove" /> */}  preview
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>}
        {!payments &&<Row>
          <Col md="12">
            <Card style={{display: 'flex', justifyContent: 'center' , alignItems: 'center'}}>
              <CardHeader>
              <p style={{ color: "white" }}>
                  Sorry You do not have any payment records
                </p>
              </CardHeader>
              <CardBody>
                
                <ul>
                  <img src={require("../assets/img/anime3.png").default} alt="Anime"></img>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>}
        <Modal modalClassName="modal-black"  isOpen={receiptModal} toggle={toggleReceipt}  modalClassName="modal-info " style={{maxWidth: "900px"}} >
            <ModalHeader toggle={toggleReceipt}>Receipt</ModalHeader>
            <ModalBody>
                <Receipt props={selectedItem}/>
            </ModalBody>
            <ModalFooter>           
            </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Payments;

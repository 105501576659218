import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminPayment from "./common/AdminPayment";
import Receipt from "./common/receipt";
import moment from "moment";
import ReactDatetime from "react-datetime";
import Swal from "sweetalert2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Row,
  Col,
  CardTitle,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
function Reconciliation() {
  const [modal, setModal] = useState(false);
  const [file, setfile] = useState(false);
  const [uploadSuccessful, setuploadSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const toggle = () => setModal(!modal);
  const reconcile = () => {
    let formData = new FormData();
    console.log("filw", file.file)
    formData.append("reconciliation", file.file);
    console.log("filw ddd", formData)
    setModal(true);

    axios({
      url: window.server + "/payments/reconciliation",
      method: "POST",
      data: formData,
      headers: {
        'Access-Control-Allow-Headers': 'X-Requested-With,content-type, Accept',
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
      
    })
      .then(async (response) => {
        console.log(response);

        if (response.status == "201") {
          setuploadSuccessful(true);
          setMessage("error 201");
          window.location.reload();
          alert("error");
          setModal(false);
        } else {
          Swal.fire({
            title: " Success!",
            text: "",
            confirmButtonText: "ok",
          });
          setModal(false);
        }
      })
      .catch((d) => {
        //console.log("Error saving the data");
        //console.log(d);
        setModal(false);
      });
  };
  useEffect(() => {}, []);
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                    <CardHeader>
                      <div
                        style={{
                          display: "flex",
                          flexDirection:"column",
                          margin:'50px',
                          justifyContent: "space-between",
                        }}
                      >
                        <CardTitle tag="h4">Mpesa Reconciliations</CardTitle>
                        <div>
                        <Form>
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <Label>Pick Excel File</Label>
                                <Input
                                  type="file"
                                  class="form-control"
                                  id="fileInput1"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter file"
                                  onChange={(e) => setfile({ file: e.target.files[0] })}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>
                        <Button
                          className=""
                          color="primary"
                          id="tooltip639225725"
                          disabled={!file}
                          onClick={() => reconcile()}
                        >
                          <i className="tim-icons  icon-simple-add" />  Upload 
                        </Button>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody></CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal visible={modal} dismiss={false} close={() => toggle()}>
          <div>
            <div className="d-flex flex-row align-items-center">
              {uploadSuccessful ? (
                <h2>Loading......</h2>
              ) : (
                <div className="lds-spinner">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              )}
              <div className="ml-5 text-dark">
                {!uploadSuccessful ? (
                  <h3>Uploading ...</h3>
                ) : (
                  <h3>{/* {message} */}</h3>
                )}
              </div>
            </div>
            {uploadSuccessful && (
              <div className="text-center">
                <button
                  className="btn btn-primary mt-4 px-5"
                  onClick={() =>
                    this.setModal(false, function () {
                      window.location.reload();
                    })
                  }
                >
                  Done
                </button>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Reconciliation;

import axios from "axios";
import React , {useEffect , useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

function UserProfile() {
  const [userDetails , setUsers] = useState(null)

  const getResource = (endpoint, setter) => {
    /* axios( {
      url:window.server + endpoint,
      method: 'get',
      data: null
    }) */
    const active_user = localStorage.user_id
    console.log(active_user)
    axios({
      url:window.server + endpoint ,
      method: "GET",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      },
      params: {"id":active_user}
      })
    .then( async response => {      
      const data = response.data
      console.log("users", data)
      setter(data.data)
    })
  }

  useEffect (() => {
    console.log("users")
    getResource(`/Users/${localStorage.user_id}`, setUsers) 
  }, [])
  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label>Role</label>
                        <Input
                          placeholder={userDetails ? userDetails.role : 'N/A' }
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="3">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          placeholder={userDetails ?  userDetails.firstName : 'N/A' }
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Input  type="email" disabled placeholder={userDetails ? userDetails.email : 'N/A'} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          placeholder={userDetails ? userDetails.firstName : ''}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          placeholder={userDetails ? userDetails.lastName :  "N/A"}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>phone</label>
                        <Input
                          placeholder={userDetails ?  userDetails.phone : 'N/A'}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>House Number</label>
                        <Input
                          placeholder={userDetails ? userDetails.house_number : 'N/A'}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Building</label>
                        <Input
                          placeholder={userDetails ? userDetails.building : 'N/A'}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Region</label>
                        <Input 
                        placeholder={userDetails ?  userDetails.region : 'N/A'}
                          disabled
                           type="number" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        <label>Details</label>
                        <Input
                          cols="80"
                          placeholder={userDetails ?  userDetails.details : 'Description goes here'}
                          disabled
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
               {/*  <Button className="btn-fill" color="primary" type="submit">
                  Save
                </Button> */}
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={ /* userDetails ? userDetails.profile_photo : */ require("../../assets/img/emilyz.jpg").default}
                    />
                    <h5 className="title">{userDetails ? (userDetails.firstName + userDetails.lastName)  : 'Saka Taka User'}</h5>
                  </a>
                  <p className="description">{userDetails ? userDetails.role : 'User'}</p>
                </div>
                <div className="card-description">
                {userDetails ?userDetails.description : "Saka Taka User Description "}
                </div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserProfile;

import React , {useState, useEffect} from "react";
import axios from 'axios';
import SearchResidence from './common/SearchResidence'
import ViewPayments from "./common/viewPayments";
import AdminPayment from "./common/agentPayment";
import Receipt from "./common/receipt";
import moment from 'moment'
import Swal from 'sweetalert2'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardTitle,
  Table,  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import ReactDatetime from "react-datetime";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search  , CSVExport} from 'react-bootstrap-table2-toolkit';

function Residence() {
  const [modal, setModal] = useState(true);
  const [modal1, setModal1] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [Residence, setResidence] = useState({rows:[]});
  const [startDate, setStart] = useState(moment().startOf('month'));
  const [receiptModal, setReceiptModal] = useState(false);
  const toggleReceipt = () => setReceiptModal(!receiptModal);
  const [modalCollect, setModalCollect] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [endDate, setEnd] = useState(moment().endOf('month'));
  const [paginationSize, updatepaginationSize] = React.useState(5); 
  const [pageStartIndex, updatepageStartIndex] = React.useState(0);
  const [offset, updatepaginationOffset] = React.useState(5)
  const toggleCollect = () => setModalCollect(!modalCollect);
  const [page, updatePage] = React.useState(0)
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const toggle = () => setModal(!modal);
  const toggle1 = () => setModal1(!modal1);
  const toggleConfirm = () => {
    console.log("toggle")
    setModalConfirm(!modalConfirm)
  };
  const actionsFormatter = (cell, row) => {
    console.log(); // this one doesn't update when I trigger "handleAcitionsClick", it just triggers when the component 'mounts', which would be all `null`
    return (
      <Row >
      <Button
      className=" btn-round btn-sm"
      color="primary"
      id="tooltip639225725"
      onClick={() => {
        setModalCollect(true)
        //setEditModal(true);
      }}
    >
      <i className="tim-icons  icon-pencil" /> 
      Collect Payment
    </Button>
    {/* <Button
      className=" btn-round btn-sm"
      color="danger"
      id="tooltip639225725"
      onClick={() => {
        setModalConfirm(true);
      }}
    >
      <i className="tim-icons  icon-trash-simple" /> 
      Send Sms Reminder
    </Button> */}
    </Row>
    );
};
const actionsFormatter1 = (cell, row) => {
  console.log("dddddddd", row); // this one doesn't update when I trigger "handleAcitionsClick", it just triggers when the component 'mounts', which would be all `null`
  return (
    <Row >
      {row.status &&<Button
      style={{padding:'5px'}}
      className=" btn-round btn-sm"
      color="primary"
      id="tooltip639225725"
      onClick={() => {
        setSelectedItem(row)
       setReceiptModal(true)
      }}
    >
      Receipt
    </Button>}
  {/* <Button
    className=" btn-round btn-sm"
    color="primary"
    id="tooltip639225725"
    onClick={() => {
      //setOccupantEdit(true);
    }}
  >
    <i className="tim-icons  icon-pencil" /> 
  </Button> */}
  </Row>
  );
};
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <p>{`House Payments   - ${row.Payment.length}`}</p>
        {row.Payment ? <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
                            data={ row.Payment } columns={ Column2 } /> : ''}
      </div>
    ),
  };
  const Columns = [{
    dataField: 'id',
    text: 'House ID'
  }, {
    dataField: 'number',
    text: 'House Number'
  }, {
    dataField: 'charge',
    text: 'Charge'
  },/*  {
    dataField: 'expected_payment',
    text: 'Expected Payment'
  }, */{
    dataField: 'monthsPayment.January',
    text: 'January'
  },{
    dataField: 'monthsPayment.February',
    text: 'February'
  },{
    dataField: 'monthsPayment.March',
    text: 'March'
  },{
    dataField: 'monthsPayment.April',
    text: 'April'
  },
  {
    dataField: 'monthsPayment.May',
    text: 'May'
  },
  {
    dataField: 'monthsPayment.June',
    text: 'June'
  },
  {
    dataField: 'monthsPayment.July',
    text: 'July'
  },{
    dataField: 'monthsPayment.August',
    text: 'August'
  },
  {
    dataField: 'monthsPayment.September',
    text: 'September'
  },
  {
    dataField: 'monthsPayment.November',
    text: 'November'
  },
  {
    dataField: 'monthsPayment.December',
    text: 'December'
  },
  // {
  //   dataField: 'total_payment',
  //   text: 'Total Paid'
  // },{
  //   dataField: 'in_arrears',
  //   text: 'In Arrears'
  // }, {
  //   dataField: 'active',
  //   text: 'Active'
  // },
  {dataField: "actions",
  text: "Actions",
  formatter: actionsFormatter
}];
  const Column2 = [
    {
      dataField: "id",
      text: "Payment Id",
    },
    {
      dataField: 'createdAt',
      text: 'Date Paid', 
      formatter: (cell) => {
        return moment(new Date(cell).toLocaleDateString()).format("DD/MM/YYYY")
      }
    },
    {
      dataField: "reference",
      text: "reference",
    },
    {
      dataField: "transactionId",
      text: "transactionId",
    },
    {
      dataField: "amount",
      text: "amount",
    },
    {
      dataField: "method",
      text: "method",
    },
    
   { dataField: "status", 
    text: "status",
  },
  
 { dataField: "receivedBy",
  text: "receivedBy",
},
    {dataField: "actions",
      text: "Actions",
      formatter: actionsFormatter1
    }
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
  const paginationOption = {
  sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }// A numeric array is also available. the purpose of above example is custom the text
  };
  const getResidence = () => {        
    console.log(startDate);
    console.log(endDate);
    //alert(values);
    let q = {
    };
    console.log("q", q)
    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    console.log("urlParams", urlParams);

axios({
  url:window.server + `/housePayments/agent/?page=${0}&size=${100000000}`+`&from=${startDate}&to=${endDate}&${urlParams}&status=paid&agent_id=${localStorage.user_id}`, 
  method: "GET",      
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "X-TENANT-ID": window.tenantId,
    Authorization: `Bearer ${localStorage.token}`,
  }
})
.then( async response => {      
  const data = response.data
  console.log("residence", data.data.rows)
    setResidence(data.data)
})
}
  const addReminderSms = (payload) => {
    console.log("data", payload)
    const all = payload
    const period = moment().format("MMMM");
   if(payload.Resident.length > 0) {
    payload.Resident.forEach(data => {
      const user_name = data.firstName
      const user_phone = data.phone
      const charge = all.charge
      const house_number = all.number
      const payload = {
        message: `Dear ${data.firstName} your payment  of ${charge} for House number ${house_number} for garbage collection period (${period}), is Due. Please pay to continue with our services.For any enquiries call us on  ${'+254720 396 310'}`,
        contact: data.phone,
        type: "direct",
      }
      axios({
        url: window.server + "/sms",
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "X-TENANT-ID": window.tenantId,
          Authorization: `Bearer ${localStorage.token}`,
        },
        data:{...payload}
      }).then(async (response) => {
        const data = response;
        console.log("  Added", data);
        Swal.fire({
          title:  'Success!',
          text: `Reminder sent to ${user_name} on the number ${user_phone} `,
          confirmButtonText:  'ok'
        }) 
      });
    }); 
  }else {
    Swal.fire({
      title:  'Error!',
      text:"There are no residents to attached to the house currently",
      confirmButtonText:  'ok'
    }) 
  }
  };
  const ScheduleReminderSms = () => {
    const period = moment().format("MMMM");
    axios({
      url: window.server + "/sms/paymentReminder",
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      }
    }).then(async (response) => {
      const data = response;
      console.log("  Added", data);
      setModalConfirm(false);
      Swal.fire({
        title:  'Success!',
        text: `Reminder sent to All non paid customers for this month `,
        confirmButtonText:  'ok'
      }) 
    });
  };
  useEffect (() => {
    getResidence()
  }, [])
  return (
    <>
      <div className="content">
       {Residence && <Row>
          <Col md="12">
            <Card>
              <CardHeader>
              </CardHeader>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">House Payment</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <ToolkitProvider
  keyField="id"
  data={ Residence.rows }
  columns={ Columns }  
  search
  exportCSV
>
  {
    props => (
      <div>
        <Row style={{display:'flex', justifyContent:'space-between'}}>
          <div>
              <span style={{padding:'5px'}}> <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton></span>
            
            <span><SearchBar { ...props.searchProps } /></span>
          </div>
          <div>
            <Button color='primary' onClick={() => {
         setModalCollect(true)
      }}>Collect Payment</Button>
          </div>
        
        </Row>
        
        <hr />
        <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
        pagination={ paginationFactory(paginationOption) }
        expandRow={expandRow}
          { ...props.baseProps } 
        />
      </div>
    )
  }
</ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>
          </Col>

        </Row> 
        }
        {!Residence && 
        <>
        <div className="content">
          <Row>
            <Col md="12" style={{height:'70vh'}}>
             <SearchResidence></SearchResidence>
             </Col>
            </Row>
            <Modal isOpen={modal} toggle={toggle}/*  className={className} */>
                <ModalHeader toggle={toggle}>   No residence on record</ModalHeader>
                <ModalBody>
                    Sorry you do not have any residence registered with us , Please Add your residence
                </ModalBody>
                <ModalFooter>
                <Button
                    className=" btn-round"
                    color="primary"
                    id="tooltip639225725"
                    onClick={() => {
                      setModal(false) 
                      setModal1(true) 
                    }}
                  >
                    <i className="tim-icons  icon-zoom-split" /> Add Residence
                  </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modal1} toggle={toggle1}/*  className={className} */>
                <ModalHeader toggle={toggle1}>Add Your Residence</ModalHeader>
                <ModalBody>
                </ModalBody>
                <ModalFooter>
                <Button
                    className="btn-icon btn-round"
                    color="primary"
                    id="tooltip639225725"
                    onClick={() => {
                      setModal1(false) 
                    }}
                  >
                    <i className="tim-icons  icon-zoom-split" />
                  </Button>
                </ModalFooter>
            </Modal>
            
        </div>
        </>
        
        }  
        <Modal isOpen={modalConfirm} toggle={toggleConfirm}/*  className={className} */>
                <ModalHeader toggle={toggleConfirm}>  Confirm </ModalHeader>
                <ModalBody>
                   Confirm that you want to send A reminder sms to all non paying customers this month ?
                </ModalBody>
                <ModalFooter>
                <Button
                    className=" btn-round"
                    color="primary"
                    id="tooltip639225725"
                    onClick={() => {
                      ScheduleReminderSms()
                    }}
                  >
                    <i className="tim-icons  icon-zoom-split" /> Confirm
                  </Button>
                </ModalFooter>
            </Modal>  
            <Modal isOpen={modalCollect} toggle={toggleCollect} /*  className={className} */>
          <ModalHeader toggle={toggleCollect}>Add Payment</ModalHeader>
          <ModalBody>
            <AdminPayment />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>    
        <Modal
          isOpen={receiptModal}
          toggle={toggleReceipt}
          modalClassName="modal-info "
          style={{ maxWidth: "900px" }}
        >
          <ModalHeader toggle={toggleReceipt}>Receipt</ModalHeader>
          <ModalBody>
            <Receipt props={selectedItem} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>        
      </div>
    </>
  );
}
export default Residence;

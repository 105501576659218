import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardTitle,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
function Users() {
  const [users, setUsers] = useState({ rows: [] });
  const [firstName, updateFirstName] = useState(null);
  const [lastName, updateLastName] = React.useState("");
  const [phone, updatePhone] = React.useState("");
  const [email, updateEmail] = React.useState("");
  const [role, updateRole] = React.useState("");
  const [searchrole, updateSearchRole] = React.useState("");
  const [searchActive, updateSearchActive] = React.useState("");
  const [file, setfile] = useState(false);
  const [uploadSuccessful, setuploadSuccessful] = useState(false);
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const actionsFormatter = (cell, row) => {
    console.log(); // this one doesn't update when I trigger "handleAcitionsClick", it just triggers when the component 'mounts', which would be all `null`
    return (
      <Row>
        <Button
          className=" btn-round btn-sm"
          color="primary"
          id="tooltip639225725"
          onClick={() => {
            setEditModal(true);
          }}
        >
          <i className="tim-icons  icon-pencil" />
        </Button>
        <Button
          className=" btn-round btn-sm"
          color="danger"
          id="tooltip639225725"
          onClick={() => {
            setSelectedItem(row);
            setDelModal(true);
          }}
        >
          <i className="tim-icons  icon-trash-simple" />
        </Button>
      </Row>
    );
  };
  const Columns = [
    {
      dataField: "id",
      text: "User ID",
    },
    {
      dataField: "firstName",
      text: "First Name",
    },
    {
      dataField: "lastName",
      text: "Last Name",
    },
    {
      dataField: "phone",
      text: "Phone",
    },
    {
      dataField: "role",
      text: "Role",
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: actionsFormatter,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const paginationOption = {
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    }, // A numeric array is also available. the purpose of above example is custom the text
  };
  const getResource = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then(async (response) => {
      const data = response.data;
      console.log("use", data.data);
      setter(data.data);
    });
  };
  const addUser = () => {
    axios({
      url: window.server + "/users",
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
        "X-TENANT-ID": window.tenantId,
      },
      data: {
        firstName,
        lastName,
        role,
        /*   email,  */
        phone,
      },
    })
      .then(async (response) => {
        const data = response;
        Swal.fire({
          title: " Success!",
          text: response.data.message,
          confirmButtonText: "ok",
        });
        getResource("/users", setUsers);
        setDelModal(false);
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          title: "Error!",
          text: error.message,
          confirmButtonText: "ok",
        });
      });
  };
  const importMass = () => {
    let formData = new FormData();
    console.log("filw", file.file);
    formData.append("reconciliation", file.file);
    console.log("filw ddd", formData);
    setModal(true);

    axios({
      url: window.server + "/users/massAdd",
      method: "POST",
      data: formData,
      headers: {
        "Access-Control-Allow-Headers": "X-Requested-With,content-type, Accept",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
      .then(async (response) => {
        console.log(response);

        if (response.status == "201") {
          setuploadSuccessful(true);
          //setMessage("error 201");
          window.location.reload();
          alert("error");
          setImportModal(false);
        } else {
          Swal.fire({
            title: " Success!",
            text: "",
            confirmButtonText: "ok",
          });
          setImportModal(false);
        }
      })
      .catch((d) => {
        //console.log("Error saving the data");
        //console.log(d);
        setModal(false);
      });
  };
  const DeleteUser = () => {
    axios({
      url: window.server + "/users/deactivate/" + selectedItem.id,
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
        "X-TENANT-ID": window.tenantId,
      },
    })
      .then(async (response) => {
        const data = response;
        console.log("useR deleted", data);
        Swal.fire({
          title: " Success!",
          text: response.data.message,
          confirmButtonText: "ok",
        });
        getResource("/users", setUsers);
        setDelModal(false);
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          title: "Error!",
          text: error.message,
          confirmButtonText: "ok",
        });
      });
  };
  const [modal, setModal] = useState(false);
  const [delmodal, setDelModal] = useState(false);
  const [editmodal, setEditModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleImport = () => setImportModal(!importModal);
  const toggleDel = () => setDelModal(!delmodal);
  const toggleEdit = () => setEditModal(!editmodal);
  useEffect(() => {
    getResource(
      `/users?page=${0}&size=${1000000}&role=${searchrole}&active=${searchActive}`,
      setUsers
    );
  }, []);
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                {/*  <h5 className="title">Users</h5>
                 */}
              </CardHeader>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                    <CardHeader>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <CardTitle tag="h4">All Users</CardTitle>
                        <Button
                          className=" btn-neutral btn-round btn-simple"
                          color="default"
                          onClick={() => toggle()}
                        >
                          {" "}
                          Add User
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <CardTitle tag="h4">All Users</CardTitle>
                        <Button
                          className=" btn-neutral btn-round btn-simple"
                          color="default"
                          onClick={() => toggleImport()}
                        >
                          {" "}
                          Import Users
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ToolkitProvider
                        keyField="id"
                        data={users.rows}
                        columns={Columns}
                        search
                        exportCSV
                      >
                        {(props) => (
                          <div>
                            <span style={{ padding: "5px" }}>
                              {" "}
                              <ExportCSVButton {...props.csvProps}>
                                Export CSV!!
                              </ExportCSVButton>
                            </span>

                            <span>
                              <SearchBar {...props.searchProps} />
                            </span>
                            <hr />
                            <BootstrapTable
                              keyField="id"
                              striped
                              bordered={true}
                              wrapperClasses="table-responsive"
                              pagination={paginationFactory(paginationOption)}
                              {...props.baseProps}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal
          modalClassName="modal-black"
          isOpen={modal}
          toggle={toggle} /*  className={className} */
        >
          <ModalHeader toggle={toggle}>Add User</ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>First Name</Label>
                    <Input
                      placeholder="John"
                      type="text"
                      onChange={(e) => updateFirstName(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Last Name</Label>
                    <Input
                      placeholder="Doe"
                      type="text"
                      onChange={(e) => updateLastName(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md="12">
                  <FormGroup>
                    <Label>email</Label>
                    <Input
                      placeholder="agent@sakataka.co.ke"
                      type="email"
                      onChange={(e) => {
                        updateEmail(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col> */}
                <Col md="12">
                  <FormGroup>
                    <Label>Phone</Label>
                    <Input
                      placeholder="0754673457"
                      type="text"
                      onChange={(e) => {
                        updatePhone(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Role</Label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        updateRole(e.target.value);
                      }}
                    >
                      <option value="Select Role">- Select Role -</option>
                      <option key="admin" value="admin">
                        Admin
                      </option>
                      <option key="manager" value="manager">
                        Manager
                      </option>
                      <option key="Agent" value="agent">
                        Agent
                      </option>
                      <option key="user" value="user">
                        User
                      </option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-icon btn-round"
              color="primary"
              id="tooltip639225725"
              onClick={() => {
                setModal(false);
                addUser();
              }}
            >
              <i className="tim-icons  icon-zoom-split" />
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-black"
          isOpen={modal}
          toggle={toggle} /*  className={className} */
        >
          <ModalHeader toggle={toggle}>Add User</ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>Pick Excel File</Label>
                    <Input
                      type="file"
                      class="form-control"
                      id="fileInput1"
                      aria-describedby="emailHelp"
                      placeholder="Enter file"
                      onChange={(e) => setfile({ file: e.target.files[0] })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-icon btn-round"
              color="primary"
              id="tooltip639225725"
              onClick={() => {
                setImportModal(false);
                importMass();
              }}
            >
              <i className="tim-icons  icon-zoom-split" />
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-black"
          isOpen={delmodal}
          toggle={toggleDel} /*  className={className} */
        >
          <ModalHeader toggle={toggleDel}>Confirmation</ModalHeader>
          <ModalBody>
            <p>
              Are You sure You want to delete this user ? {selectedItem._id}{" "}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              className=" btn-round"
              color="danger"
              onClick={() => {
                DeleteUser();
                //console.log(this.state.response.data[0]);
              }}
            >
              <i className="tim-icons icon-simple-remove" />
              Yes im sure
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-black"
          isOpen={editmodal}
          toggle={toggleEdit} /*  className={className} */
        >
          <ModalHeader toggle={toggleEdit}>Edit</ModalHeader>
          <ModalBody>
            <p>Edit</p>
          </ModalBody>
          <ModalFooter>
            <Button
              className=" btn-round"
              color="danger"
              onClick={() => {
                //console.log(this.state.response.data[0]);
              }}
            >
              <i className="tim-icons icon-simple-pencil" />
              Edit
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Users;

import React from "react";

const Alert2 = (props) => {
  console.log("alert props",props)
  return (
    <></>
    /* Swal.fire({
      title: props.title || 'Error!',
      text: props.text || 'Do you want to continue',
      icon: props.icon || 'error',
      confirmButtonText: props.confirmButtonText || 'Cool'
    }) */
  )
}

export default Alert2;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

function Payment() {
  const [Residence, setResidence] = useState(null);
  const [Agent, setAgent] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedResidence , setSelectedResidence] = useState("")
  const [Amount, setAmount] = useState(5);
  const toggle = () => setModal(!modal);
  const toggle1 = () => setModal1(!modal1);
  const getResidence = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      },
    }).then(async (response) => {
      const data = response.data;
      console.log("reisdence.... ", data)
      if(data.data.length > 0){
        const user = data.data
        if(user[0].Residence.length > 0) {
          setter(data.data)
        }
        
        }
    });
  }
  const addMpesaPayment = () => {
    let data  = {
        "msisdn":window.verifyNumber(localStorage.user_phone),
        "amount":Amount,
        "method":"mpesa",
        "user":localStorage.user_id,
        "residence":selectedResidence,
        "description":`Mpesa Payment for House ${selectedResidence} By user ${localStorage.user_id}`
      }
    console.log("mpesa", data)
    setModal1(false);
    axios({
        url:window.server + '/payments' ,
        method: "POST",      
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "X-TENANT-ID":window.tenantId,
          'Authorization': `Bearer ${localStorage.token}`
        }, 
        data: data
      })
      .then( async response => {      
        const data = response.data
        console.log("region", data)
        window.location = '/user/payments'
      }).catch( err => {
          console.log(err)
      })
  }
  const addCashPayment = () => {
    let data = {
      "msisdn":window.verifyNumber(localStorage.user_phone),
      "amount":Amount,
      "method":"cash",
      "agent":selectedAgent,
      "user":localStorage.user_id,
      "residence":selectedResidence,
      "description":`Cash Payment for House ${selectedResidence} By user ${localStorage.user_id}`
    }
    console.log("cash", data)
    setModal(false);
   axios({
      url:window.server + '/payments' ,
      method: "POST",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      }, 
      data: data
    })
    .then( async response => {      
      const data = response.data
      console.log("region", data)
      window.location = '/user/payments'
    }).catch( err => {
        console.log(err)
    })
  }
    
  const getAgent = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      },
    }).then(async (response) => {
      const data = response.data;
      console.log("agents", data.data);
      setter(data.data);
    });
  };
  useEffect(() => {
    getResidence(
      `/occupants/?id=${localStorage.user_id}`,
      setResidence
    );
    getAgent(`/Users?role=agent`, setAgent);
  }, []);
  return (
    <>
      {Residence && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className=" btn-round"
              color="primary"
              id="tooltip639225725"
              onClick={() => {
                toggle1();
              }}
            >
              <i className="tim-icons  icon-zoom-split" />
              <p>MPESA</p>
            </Button>
            <Button
              className=" btn-round"
              color="primary"
              id="tooltip639225725"
              onClick={() => {
                toggle();
              }}
            >
              <i className="tim-icons  icon-zoom-split" />
              <p>CASH</p>
            </Button>
          </div>
          <Modal modalClassName="modal-black"   isOpen={modal} toggle={toggle} /*  className={className} */>
            <ModalHeader toggle={toggle}>Cash Payment</ModalHeader>
            <ModalBody>
              <Form>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Agent</Label>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setSelectedAgent(e.target.value);
                        }}
                      >
                        <option value="Select Region">- Select Agent -</option>
                        {Agent.map((el, i) => (
                          <option
                            key={i}
                            value={el.id}
                          >
                            {el.firstName ? el.firstName : 'N/A'}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Residence</Label>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setSelectedResidence(e.target.value);
                        }}
                      >
                        <option value="Select Region">- Select Residence -</option>
                        {Residence[0].Residence.map((el, i) => (
                          <option
                            key={i}
                            value={el.id}
                          >
                            {el.number }
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Amount</Label>
                      <Input
                        placeholder="300"
                        type="Number"
                        style={{ color: "black" }}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                className=" btn-round"
                color="primary"
                id="tooltip639225725"
                onClick={() => {
                  addCashPayment()
                }}
              >
                <i className="tim-icons  icon-zoom-split" /> Make Payment
              </Button>
            </ModalFooter>
          </Modal>
          <Modal modalClassName="modal-black"  isOpen={modal1} toggle={toggle1} /*  className={className} */>
            <ModalHeader toggle={toggle1}>Mpesa Payment</ModalHeader>
            <ModalBody>
              <Card>
                <CardBody>
                  <p style={{ color: "white" }}>
                    Confirm that the number below is the number you wish to
                    receive the Mpesa request
                  </p>
                  <ul>
                    <li> Your Phone: {window.verifyNumber(localStorage.user_phone)}</li>
                  </ul>
                  <Col md="12">
                    <FormGroup>
                      <Label>Residence</Label>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setSelectedResidence(e.target.value);
                        }}
                      >
                        <option value="Select Region">- Select Residence -</option>
                        {Residence[0].Residence.map((el, i) => (
                          <option
                            key={i}
                            value={el.id}
                          >
                            {el.number }
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button
                className=" btn-round"
                color="primary"
                id="tooltip639225725"
                onClick={() => {
                    addMpesaPayment()                  
                }}
              >
                <i className="tim-icons  icon-zoom-split" /> Make Payment
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
      {!Residence && (
        <>
          <Row>
            <Col md="12">
              <p>
                Sorry you do not have any residence registered with us , Please
                add your residence to proceed with payment.{" "}
              </p>
              <Button
                className=" btn-round"
                color="primary"
                id="tooltip639225725"
                onClick={() => {
                    window.location = "/user/residence"                 
                }}
              >
                <i className="tim-icons  icon-zoom-split" /> Add Residence
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default Payment;
